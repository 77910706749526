// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cn-index-js": () => import("./../../../src/pages/cn/index.js" /* webpackChunkName: "component---src-pages-cn-index-js" */),
  "component---src-pages-cn-integration-just-list-js": () => import("./../../../src/pages/cn/integration/just-list.js" /* webpackChunkName: "component---src-pages-cn-integration-just-list-js" */),
  "component---src-pages-cn-integration-memory-helper-js": () => import("./../../../src/pages/cn/integration/memory-helper.js" /* webpackChunkName: "component---src-pages-cn-integration-memory-helper-js" */),
  "component---src-pages-cn-privacy-policy-js": () => import("./../../../src/pages/cn/privacy-policy.js" /* webpackChunkName: "component---src-pages-cn-privacy-policy-js" */),
  "component---src-pages-cn-terms-of-services-js": () => import("./../../../src/pages/cn/terms-of-services.js" /* webpackChunkName: "component---src-pages-cn-terms-of-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms-of-services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-pages-user-guide-js": () => import("./../../../src/pages/user-guide.js" /* webpackChunkName: "component---src-pages-user-guide-js" */)
}

